<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        {{ i18n('maintenanceType.title') }}
      </h2>
    </div>

    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div
        class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
      >
        <div class="flex mt-5 sm:mt-0">
          <button
            class="btn bg-theme-31 text-white sm:w-auto pb-1 pt-1"
            @click="$router.push('/maintenanceType/new')"
          >
            <PlusIcon class="w-5 h-5 mr-2 ml-2" /> {{ i18n('common.create') }}
          </button>
          <button
            id="tabulator-print"
            class="btn btn-outline-secondary dark:text-white w-1/2 sm:w-auto mr-2 ml-2"
            @click="onPrint"
          >
            <PrinterIcon class="w-4 h-4 mr-2 ml-2" />
            {{ i18n('common.table.print') }}
          </button>
          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary dark:text-white w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
              {{ i18n('common.table.export') }}
              <ChevronDownIcon class="w-4 h-4 sm:ml-2 sm:mr-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onDownloadPdf"
                >
                  <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
                  {{ i18n('common.table.exportOptions.exportToPDF') }}
                </a>
                <a
                  id="tabulator-export-html"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
                  {{ i18n('common.table.exportOptions.exportToXLSX') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div ref="table" class="relative">
          <loading-spinner
            type="half-circle"
            :duration="1000"
            :size="40"
            color="#000"
            :loading="loading"
          />
          <div class="overflow-x-auto">
            <table
              class="table table-report sm:mt-2"
              ref="tableRef"
              id="maintenanceTypeTable"
            >
              <thead>
                <tr>
                  <th class="whitespace-nowrap w-10">#</th>
                  <th
                    v-for="col in columns"
                    :key="col.name"
                    :id="col.name === 'action' ? 'ignore-1' : ''"
                    class="text-center whitespace-nowrap"
                  >
                    {{ i18n(col.label) }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="rows.length">
                <tr v-for="(row, index) in rows" :key="index" class="intro-x">
                  <td class="w-10">
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      {{ rowIndex(index) }}
                    </div>
                  </td>
                  <td class="text-center flex items-center justify-center">
                    <app-list-item-image :value="row.logo"></app-list-item-image>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      <span>{{ presenterLocalization(row, 'name') }}</span>
                    </div>
                  </td>
                  <td class="w-40">
                    <div
                      class="flex items-center justify-center whitespace-nowrap"
                      :class="{
                        'text-theme-24': !row.isEnabled,
                        'text-green-600 dark:text-theme-30': row.isEnabled
                      }"
                    >
                      <CheckSquareIcon class="w-4 h-4 mr-2 ml-2" />
                      {{
                        row.isEnabled
                          ? i18n('common.activated')
                          : i18n('common.disabled')
                      }}
                    </div>
                  </td>
                  <td class="table-report__action w-56" id="ignore-2">
                    <div class="flex justify-center items-center gap-4">
                      <!-- <button type="button" class="text-theme-31 font-medium" @click="showModal('addImage', row.id)">
                        <Tippy tag="div" class="flex items-center gap-1 whitespace-nowrap" :content="i18n('common.addImage')">
                          <UploadIcon class="w-4 h-4" /> {{ i18n('common.addImage') }}
                        </Tippy>
                      </button> -->
                      <router-link
                        :to="`/maintenanceType/${row.id}/edit`"
                        class="text-theme-10 dark:text-theme-30 font-medium flex items-center gap-1 whitespace-nowrap"
                      >
                        <Tippy
                          tag="div"
                          class="flex items-center gap-1 whitespace-nowrap"
                          :content="i18n('common.edit')"
                        >
                          <EditIcon class="w-4 h-4" /> {{ i18n('common.edit') }}
                        </Tippy>
                      </router-link>
                      <button
                        v-if="!row.isEnabled"
                        @click="toggleStatus(row.id, true)"
                        class="flex justify-center items-center gap-2 p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md text-green dark:text-theme-30"
                        style="color: green"
                      >
                        <UnlockIcon class="w-4 h-4 mr-1 ml-1" />
                        {{ i18n('common.activate') }}
                      </button>
                      <button
                        v-if="row.isEnabled"
                        @click="toggleStatus(row.id, false)"
                        class="flex justify-center items-center gap-2 p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md text-theme-24 dark:text-theme-24"
                      >
                        <LockIcon class="w-4 h-4 mr-1 ml-1" />
                        {{ i18n('common.disable') }}
                      </button>
                      <!-- <button
                        type="button"
                        class="text-theme-24 font-medium"
                        @click="showModal(row.id)"
                      >
                        <Tippy
                          tag="div"
                          class="flex items-center gap-1 whitespace-nowrap"
                          :content="i18n('common.delete')"
                        >
                          <Trash2Icon class="w-4 h-4" />
                          {{ i18n('common.delete') }}
                        </Tippy>
                      </button> -->
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <app-empty-page
              v-if="!rows.length && !loading"
              :label="i18n('empty.maintenanceType')"
              label-class="text-lg font-medium"
              icon-size="4x"
              class="box w-full py-64"
            ></app-empty-page>
          </div>
          <Pagination
            v-if="rows.length"
            :page="currentPage"
            :pageSize="pagination.limit"
            :rowsNumber="pagination.total"
            :showSizeChanger="true"
            :disabled="!rows.length"
            position="center"
            @next-page="doNextPage"
            @prev-change="doPrevPage"
            @size-change="doChangePaginationPageSize"
          ></Pagination>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Delete Modal -->
    <DeleteModal item="maintenanceType" @delete="doDestroy(selectedId)" />
    <!-- END: Delete Modal -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'
import { helper as $h } from '@/utils/helper'
import print from 'print-js'
import DeleteModal from '@/components/modals/delete-modal.vue'

export default {
  components: {
    // AddImageModal,
    DeleteModal
  },
  data() {
    return {
      selectedId: null,
      columns: [
        {
          name: 'image',
          field: 'image',
          label: 'maintenanceType.fields.image',
          align: 'center'
          // required: true,
        },
        {
          name: 'name',
          field: 'name',
          label: 'maintenanceType.fields.name',
          align: 'center'
          // required: true,
        },
        {
          name: 'status',
          field: 'status',
          label: 'iam.fields.status',
          align: 'center'
          // required: true,
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'maintenanceType/list/rows',
      pagination: 'maintenanceType/list/pagination',
      listLoading: 'maintenanceType/list/loading',
      destroyLoading: 'maintenanceType/destroy/loading'
    }),
    loading() {
      return this.listLoading || this.destroyLoading
    },
    currentPage() {
      return this.pagination.currentPage || 1
    },
    rowIndex() {
      return (index) => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.maintenanceType')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.maintenanceType')
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
    //
  },
  methods: {
    ...mapActions({
      doFetch: 'maintenanceType/list/doFetch',
      doFetchNextPage: 'maintenanceType/list/doFetchNextPage',
      doFetchPreviousPage: 'maintenanceType/list/doFetchPreviousPage',
      doChangePaginationCurrentPage:
        'maintenanceType/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize:
        'maintenanceType/list/doChangePaginationPageSize',
      doToggleStatus: 'maintenanceType/list/doToggleStatus',
      doDestroy: 'maintenanceType/destroy/doDestroy'
    }),
    async toggleStatus(id, enabled) {
      id = `${id}`
      await this.doToggleStatus({ id, enabled })
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    onExportXlsx() {
      $h.exportToXlsx(this.tableRef, 'maintenanceTypeTable', 'maintenanceType')
    },
    onDownloadPdf() {
      $h.downloadPdf('#maintenanceTypeTable', 'maintenanceType', [2])
    },
    onPrint() {
      print({
        printable: 'maintenanceTypeTable',
        type: 'html',
        scanStyles: true,
        targetStyles: ['*'],
        maxWidth: 800,
        ignoreElements: ['ignore-1', 'ignore-2']
      })
    },
    showModal(id) {
      this.selectedId = id
      cash('#delete-modal-preview').modal('show')
    },
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        pagination: {
          firstPage: true,
          limit: 15,
          action: 'next',
          orderBy: 'createdAt',
          sortType: 'desc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    }
  }
}
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
